/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useTrail, a } from "@react-spring/web";
import FadeIn from "react-fade-in";
import Fade from "react-reveal/Fade";
import Snowfall from "react-snowfall";
import SnowStorm from "react-snowstorm";
// import Fade from "@material-ui/core/Fade";
import { useForm, Controller } from "react-hook-form";
import rightArrow from "./right-arrow.png";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import newCover from "./image/new-cover.png";
import newLogo from "./image/new-logo.png";
import spotifyImage from "./image/spotify.png";

import signature from "./image/new-footer-logo.png";
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import styled from "styled-components";
import "./App.scss";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    height: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2, 4, 3),
    marginRight: "10%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const StyledTextField = styled(TextField)`
  /* default */
  .MuiInput-underline:before {
    border-bottom: 2px solid #fbeeef;
  }
  /* hover (double-ampersand needed for specificity reasons. */
  && .MuiInput-underline:hover:before {
    border-bottom: 2px solid #fbeeef;
  }
  /* focused */
  .MuiInput-underline:after {
    border-bottom: 2px solid #cb4f2a;
  }
  .MuiFormHelperText-root {
    font-family: "Josefin Sans";
    font-size: 15px;
  }
`;

const StyledAutoComplete = styled(Autocomplete)`
  /* default */
  .MuiAutocomplete-endAdornment {
    top: calc(50% - 40px) !important;
  }
  .MuiInputLabel-outlined {
    margin: 0px;
    color: #e5a795;
  }
  /* focused */
  .MuiInput-underline:after {
    border-bottom: 2px solid #cb4f2a;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: #e5a795 !important;
  }
  .MuiAutocomplete-clearIndicator {
    visibility: hidden !important;
  }
  .MuiOutlinedInput-root .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #e5a795;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0px;
  .MuiTypography-body1 {
    font-size: 15px;
    color: black;
    font-family: "Josefin Sans";
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: #cb4f2a;
  }
`;

function App() {
  const classes = useStyles();
  const weddingTime = "18 December 2021, Saturday Noon";
  const weddingLocation = "Straits Ballroom";
  const weddingLocation2 = "Sofitel Sentosa Resort";
  const page2writeup1 =
    "Join the celebration as we say “I do” on a cliff overlooking the South China Sea. ";
  const page2writeup2 =
    "Complimentary valet parking is available for all guests. Alternatively, a hotel shuttle bus departs Harbourfront Station Exit D every half-hourly.";
  const page2writeup3 =
    "In view of the evolving pandemic, we do expect slight adjustments to our wedding itinerary. Full details will be provided nearer to the day. ";
  const page2writeup4 =
    "In the meantime, we would love to have your input.  Suggest a song (or two) for our wedding playlist below: ";

  // rsvp
  const { control, handleSubmit, formState, watch, reset } = useForm();
  const [open, set] = useState(true);
  const [openProgress, setOpenProgress] = useState(false);

  // spotify
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = openDropdown && options.length === 0;
  const [tracks, setTracks] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("Wedding");
  const [selectedOption, setSelectedOption] = React.useState({});

  const handleClose = () => {
    setOpenModal(false);
  };

  React.useEffect(() => {
    async function retrieveSpotifyCredential() {
      const headers = {
        Authorization:
          "Basic MjAzOGRjNjVkMGQ2NDU4OTg2NjYyOGE4MWQ5NDAyZGU6YmQ0YjJlYmY3OTMxNDg2YWE3YjBkODI2NWEyNjBmYzE=",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      var urlencoded = new URLSearchParams();
      urlencoded.append("grant_type", "client_credentials");

      var requestOptions = {
        method: "POST",
        headers: headers,
        body: urlencoded,
        redirect: "follow",
      };

      fetch("https://accounts.spotify.com/api/token", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.error) {
            localStorage.removeItem("expire_time");
            localStorage.removeItem("access_token");
          } else {
            localStorage.setItem("access_token", result.access_token);
            localStorage.setItem("expire_time", result.expires_in);
            fetchTracks();
          }
        })
        .catch((error) => console.log("error", error));
    }

    async function fetchTracks() {
      const token = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      var requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };

      fetch(
        `https://api.spotify.com/v1/search?q=${searchValue}&type=track,artist&market=US&limit=20&offset=5`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.error) {
            localStorage.removeItem("expire_time");
            localStorage.removeItem("access_token");
          } else {
            setTracks(result);
            let options = [];
            result.tracks.items.map((track) => {
              track.artists.map((artist) => {
                options.push({
                  name: track.name + " by (" + artist.name + ")",
                  track: track.name,
                  uri: track.uri,
                  artist: artist.name,
                  url: track.external_urls.spotify,
                });
              });
            });
            setOptions(options);
          }
        })
        .catch((error) => console.log("error", error));
    }

    if (localStorage.getItem("access_token")) {
      fetchTracks();
    } else {
      retrieveSpotifyCredential();
    }
    return;
  }, [loading, searchValue]);

  React.useEffect(() => {
    async function retrieveSpotifyCredential() {
      const headers = {
        Authorization:
          "Basic MjAzOGRjNjVkMGQ2NDU4OTg2NjYyOGE4MWQ5NDAyZGU6YmQ0YjJlYmY3OTMxNDg2YWE3YjBkODI2NWEyNjBmYzE=",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      var urlencoded = new URLSearchParams();
      urlencoded.append("grant_type", "client_credentials");

      var requestOptions = {
        method: "POST",
        headers: headers,
        body: urlencoded,
        redirect: "follow",
      };

      fetch("https://accounts.spotify.com/api/token", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.error) {
            localStorage.removeItem("expire_time");
            localStorage.removeItem("access_token");
          } else {
            localStorage.setItem("access_token", result.access_token);
            localStorage.setItem("expire_time", result.expires_in);

            if (selectedOption && selectedOption.uri !== undefined)
              addTrackToPlaylist();
          }
        })
        .catch((error) => console.log("error", error));
    }

    async function addTrackToPlaylist() {
      const token = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      var requestOptions = {
        method: "POST",
        headers: headers,
        redirect: "follow",
      };

      fetch(
        `https://api.spotify.com/v1/playlists/0MSZ6oKkS2DMsDjlBqcU2c/tracks?uris=${selectedOption.uri}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    }

    if (localStorage.getItem("access_token")) {
      if (selectedOption && selectedOption.uri !== undefined)
        addTrackToPlaylist();
    } else {
      retrieveSpotifyCredential();
    }
    return;
  }, [selectedOption]);

  React.useEffect(() => {
    setInterval(function () {
      const newExpiry = localStorage.getItem("expire_time") - 1;
      if (newExpiry <= 500) {
        localStorage.removeItem("expire_time");
        localStorage.removeItem("access_token");
      } else {
        localStorage.setItem("expire_time", newExpiry);
      }
    }, 10 * 100); // 60 * 1000 milsec
    return;
  }, []);

  React.useEffect(() => {
    if (!openDropdown) {
      setOptions([]);
    }
    return;
  }, [openDropdown]);

  async function sendTrackToAirtable() {
    setOpenProgress(true);
    console.log(selectedOption, "selected option");
    let input = {};
    input = {
      fields: {
        Track: selectedOption.track,
        Artist: selectedOption.artist,
        uri: selectedOption.uri,
        URL: selectedOption.url,
        Status: "New",
      },
    };

    const request = {
      method: "POST",
      headers: {
        Authorization: `Bearer keyPARWiVJ9dOVXbf`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    };
    const url = `https://api.airtable.com/v0/appWWRT0xsRPCOkaP/SpotifyPlaylist`;

    const airTableRequest = await fetch(url, request);

    if (!airTableRequest.ok) {
      const errorResponse = await airTableRequest.json();
      return errorResponse;
    }
    setOpenModal(true);
    setOpenProgress(false);
    setTimeout(function () {
      setSelectedOption("");
    }, 1000);
  }

  // main page
  const onSubmit = async (data) => {
    setOpenProgress(true);
    let input = {};
    if (isRSVPNo) {
      input = {
        fields: {
          Guest: data.name,
          RSVP: false,
          Count: 0,
        },
      };
    } else {
      input = {
        fields: {
          Guest: data.name,
          RSVP: true,
          "Postal Code": data.postalcode,
          "Unit No.": data.unit,
          Dietary: data.dietary,
          "Plus One": data.plusone === "yes" ? true : false,
          Count: 1,
        },
      };
    }

    const request = {
      method: "POST",
      headers: {
        Authorization: `Bearer keyPARWiVJ9dOVXbf`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    };
    const url = `https://api.airtable.com/v0/appWWRT0xsRPCOkaP/Guests`;

    const airTableRequest = await fetch(url, request);

    if (!airTableRequest.ok) {
      const errorResponse = await airTableRequest.json();
      return errorResponse;
    }
    reset({
      name: "",
      rsvp: "",
      address: "",
      dietary: "",
      plusone: "",
    });
    window.scrollTo(0, 0);
    setRSVPDone(true);
    setOpenProgress(false);

    return;
  };

  const [isRSVPSubmission, setRSVPSubmission] = useState(false);
  const [isSubmissionDone, setRSVPDone] = useState(false);
  const [isRSVPNo, setRSVPNOQuestions] = useState(true);

  if (watch("rsvp") === "yes" && isRSVPNo) {
    setRSVPNOQuestions(false);
  }
  if (watch("rsvp") === "no" && !isRSVPNo) {
    setRSVPNOQuestions(true);
  }

  return (
    <div className="outer-div">
      <div
        style={{
          pointerEvents: "none",
          backgroundColor: "transparent",
          position: "absolute",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "100%",
          zIndex: 20,
        }}
      >
        <Snowfall
          // Changes the snowflake color
          color="#fbeeef"
          useTwinkleEffect={true}
          excludeMobile={false}
          vMaxX={2}
          vMaxY={-0.5}
          flakeWidth={5}
          flakeHeight={5}
          // Controls the number of snowflakes that are created (default 150)
          snowflakeCount={20}
        />
      </div>

      {openProgress && (
        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {openModal && (
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <div className={classes.paper}>
            <img
              className="spotify-success"
              src={spotifyImage}
              alt="checkCircle"
            />
            <h4
              style={{
                width: "70%",
                color: "#DCD7D0",
                textAlign: "center",
                paddingLeft: "20px",
              }}
            >
              Track has been added!
            </h4>
          </div>
        </Modal>
      )}
      {isRSVPSubmission ? (
        <div className="rsvp-container">
          <FadeIn>
            <div className="rsvp-header">
              <div className="rsvp-title"> respondez s’il vous plaît </div>
            </div>
          </FadeIn>
          {isSubmissionDone ? (
            <div className="rsvp-body">
              <FadeIn>
                <div>Your response has been submitted.</div>
                <div>
                  {isRSVPNo
                    ? "Ah.. What a pity! We really hoped you could make it."
                    : "Thank you! We’ll look forward to seeing you on 18 December 2021 :)"}
                </div>

                <div
                  onClick={() => {
                    setRSVPSubmission(false);
                    setRSVPDone(false);
                  }}
                  className="back-to-home"
                  style={{ paddingTop: 20, pointer: "cursor" }}
                >
                  Back to Home
                </div>
              </FadeIn>
            </div>
          ) : (
            <div className="rsvp-body">
              <FadeIn>
                <div
                  onClick={() => setRSVPSubmission(false)}
                  className="back-to-home rsvp-form-label"
                  style={{ pointer: "cursor", color: "#dcd7d0" }}
                >
                  Home
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <label className="rsvp-form-label">
                    Firstly, tell us who you are? *
                  </label>
                  <Controller
                    render={({ field }) => (
                      <StyledTextField
                        {...field}
                        style={{ width: "100%", paddingBottom: "20px" }}
                      />
                    )}
                    rules={{ required: true }}
                    name="name"
                    control={control}
                    defaultValue=""
                    className="materialUIInput"
                  />

                  <label className="rsvp-form-label">
                    Will you be able to join us? *
                  </label>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    defaultValue=""
                    name="rsvp"
                    render={({ field }) => (
                      <RadioGroup
                        {...field}
                        style={{ width: "100%", paddingBottom: "20px" }}
                      >
                        <StyledFormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes!"
                          className="rsvp-form-label"
                          style={{ width: "100%", fontFamily: "Josefin Sans" }}
                        />
                        <StyledFormControlLabel
                          value="no"
                          control={<Radio />}
                          className="rsvp-form-label"
                          label="Unfortunately, no :("
                          style={{ width: "100%", fontFamily: "Josefin Sans" }}
                        />
                      </RadioGroup>
                    )}
                  />

                  {!isRSVPNo && (
                    <label className="rsvp-form-label">
                      Do leave your address if you would like to receive a
                      physical mailer invitation. If not, digital invites will
                      be sent instead.{" "}
                    </label>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {!isRSVPNo && (
                      <Controller
                        render={({ field }) => (
                          <StyledTextField
                            {...field}
                            helperText="Postal Code"
                            style={{
                              width: "40%",
                              paddingBottom: "20px",
                              fontFamily: "Josefin Sans",
                            }}
                          />
                        )}
                        rules={{ required: false }}
                        name="postalcode"
                        control={control}
                        defaultValue=""
                        className="materialUIInput"
                      />
                    )}
                    {!isRSVPNo && (
                      <Controller
                        render={({ field }) => (
                          <StyledTextField
                            {...field}
                            helperText="Unit No."
                            style={{
                              width: "40%",
                              paddingBottom: "20px",
                              fontFamily: "Josefin Sans",
                            }}
                          />
                        )}
                        rules={{ required: false }}
                        name="unit"
                        control={control}
                        defaultValue=""
                        className="materialUIInput"
                      />
                    )}
                  </div>

                  {!isRSVPNo && (
                    <label className="rsvp-form-label">
                      Unfortunately, we will not be able to accommodate any
                      Plus-Ones due to seating limitations at our venue. Should
                      a space free up, will you require an additional seat? *{" "}
                    </label>
                  )}
                  {!isRSVPNo && (
                    <Controller
                      rules={{ required: true }}
                      control={control}
                      defaultValue=""
                      name="plusone"
                      render={({ field }) => (
                        <RadioGroup
                          {...field}
                          style={{ width: "100%", paddingBottom: "20px" }}
                        >
                          <StyledFormControlLabel
                            value="no"
                            control={<Radio />}
                            label="Nope :)"
                            className="rsvp-form-label"
                            style={{
                              width: "100%",
                              fontFamily: "Josefin Sans",
                            }}
                          />
                          <StyledFormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes, that will be nice!"
                            className="rsvp-form-label"
                            style={{
                              width: "100%",
                              fontFamily: "Josefin Sans",
                            }}
                          />
                        </RadioGroup>
                      )}
                    />
                  )}

                  {!isRSVPNo && (
                    <label className="rsvp-form-label">
                      Do you have any dietary restrictions?
                    </label>
                  )}
                  {!isRSVPNo && (
                    <Controller
                      render={({ field }) => (
                        <StyledTextField
                          {...field}
                          style={{ width: "100%", paddingBottom: "20px" }}
                        />
                      )}
                      name="dietary"
                      rules={{ required: false }}
                      control={control}
                      defaultValue=""
                      className="materialUIInput"
                    />
                  )}
                  <input type="submit" disabled={!formState.isValid} />
                </form>
              </FadeIn>
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className="container">
            <img src={newCover} className="new-cover" alt="newCover" />
            <Fade bottom>
              <div
                className="bottom-center"
                onClick={() => setRSVPSubmission(true)}
              >
                <div className="rsvp-line underlined underlined--offset">
                  <a className="rsvp-button" href="#">
                    RSVP
                  </a>
                  <img
                    className="right-arrow"
                    src={rightArrow}
                    alt="right arrow"
                  />
                </div>
              </div>
            </Fade>
            <Fade top>
              <img src={newLogo} className="centered" alt="newLogo" />
            </Fade>
          </div>

          {/* Page 2 */}
          <div className="page2-container">
            <div className="page2-title-container">
              <FadeIn>
                <div className="trail">eat</div>
                <div className="trail">drink</div>
                <div className="trail">be married</div>
              </FadeIn>
              {/* <div>eat</div>
              <div>drink</div>
              <div>be married</div> */}
            </div>
            <hr></hr>
            <div className="page2-sub-container">
              <div>{page2writeup1}</div>
              <br />
              <div style={{ fontWeight: "bold" }}>{weddingTime}</div>
              <div style={{ fontWeight: "bold" }}>{weddingLocation}</div>
              <div style={{ fontWeight: "bold" }}>{weddingLocation2}</div>
              <br />
              <div>{page2writeup2}</div>
              <br />
              <div>{page2writeup3}</div>
              <br />
              <div>{page2writeup4}</div>
              <div className="spotify-container">
                <StyledAutoComplete
                  id="asynchronous-demo"
                  // style={{ width: 300 }}
                  open={openDropdown}
                  onOpen={() => {
                    setOpenDropdown(true);
                  }}
                  onClose={() => {
                    setOpenDropdown(false);
                  }}
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  onChange={(event, newValue) => {
                    setSelectedOption(newValue);
                  }}
                  getOptionLabel={(option) => option.name}
                  options={options}
                  loading={loading}
                  onInputChange={(event, newInputValue) => {
                    setSearchValue(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search song tracks"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <div
                  className="add-to-playlist"
                  onClick={() => sendTrackToAirtable()}
                  disabled={
                    (selectedOption === undefined || selectedOption === null) &&
                    selectedOption.name === undefined
                  }
                >
                  <div className="spotify-line underlined underlined--offset">
                    <a
                      className="rsvp-button"
                      href="#"
                      style={{ fontSize: "20px" }}
                    >
                      Add to playlist!
                    </a>
                    <img
                      className="right-arrow-spotify"
                      src={rightArrow}
                      alt="right arrow"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Page 3 */}
          <div className="page3-container">
            <img src={signature} className="signature" alt="signature" />
            <div>of mergers and acquisitions</div>
            <div>18.12.21</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
